import axios from '@/common/axios'
import qs from 'qs'

export function addCustomer (data) {
  return axios({
    method: 'post',
    url: '/sale/customer/add',
    data: qs.stringify(data)
  })
}

export function deleteCustomer (id) {
  return axios({
    method: 'delete',
    url: '/sale/customer/delete/' + id
  })
}

export function updateCustomer (data) {
  return axios({
    method: 'put',
    url: '/sale/customer/update',
    data: qs.stringify(data)
  })
}

export function selectCustomerInfo (id) {
  return axios({
    method: 'get',
    url: '/sale/customer/info/' + id
  })
}

export function selectCustomerList (query) {
  return axios({
    method: 'get',
    url: '/sale/customer/list',
    params: query
  })
}

export function remindCustomerList (query) {
  return axios({
    method: 'get',
    url: '/sale/customer/remind',
    params: query
  })
}

export function reason (data) {
  return axios({
    method: 'post',
    url: '/sale/customer/reason',
    data: qs.stringify(data)
  })
}
