<template>
  <div id="customer">
    <el-dialog
      :title="customerFormTitle"
      width="680px"
      :visible.sync="customerDialogVisible"
      :close-on-click-modal="false"
      @close="customerDialogClose"
    >
      <el-form
        ref="customerFormRef"
        :model="customerForm"
        :rules="customerFormRules"
        label-position="right"
        label-width="100px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="客户名称" prop="customerName">
              <el-input v-model="customerForm.customerName" placeholder="请输入客户名称" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="所在区域" prop="area">
              <el-input v-model="customerForm.area" placeholder="请输入所在区域" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="邮政编码" prop="postalCode">
              <el-input v-model="customerForm.postalCode" placeholder="请输入邮政编码" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系人" prop="contactPerson">
              <el-input v-model="customerForm.contactPerson" placeholder="请输入联系人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系方式" prop="phone">
              <el-input v-model="customerForm.phone" placeholder="请输入联系方式" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系地址" prop="contactAddress">
              <el-input v-model="customerForm.contactAddress" placeholder="请输入联系地址" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="业务负责人" prop="businessOwnerId">
              <el-select
                v-model="customerForm.businessOwnerId"
                placeholder="请选择业务负责人"
                clearable
                disabled
              >
                <el-option
                  v-for="item in userList"
                  :key="item.id"
                  :label="item.realName"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="备注" prop="remarks">
              <el-input v-model="customerForm.remarks" placeholder="请输入备注" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="收货地址" prop="receivingAddress">
              <!-- <el-input v-model="customerForm.receivingAddress" placeholder="请输入收货地址" clearable /> -->
              <el-select v-model="customerForm.receivingAddress"
                multiple
                filterable
                allow-create
                default-first-option
                placeholder="请选择收货地址">
              </el-select>
            </el-form-item> 
          </el-col>
          <el-col :span="12">
            <el-form-item label="提醒时长" prop="reminderTime">
              <el-input v-model="customerForm.reminderTime" placeholder="请输入提醒时长" clearable />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="customerDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="customerFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="客户名称">
        <el-input v-model="searchForm.customerName" placeholder="请输入客户名称" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="customerPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="customerName" label="客户名称" />
      <el-table-column prop="area" label="所在区域" />
      <el-table-column prop="postalCode" label="邮政编码" />
      <el-table-column prop="contactPerson" label="联系人" />
      <el-table-column prop="phone" label="联系方式" />
      <el-table-column prop="contactAddress" label="联系地址" />
      <el-table-column prop="businessOwner" label="业务负责人" />
      <el-table-column prop="remarks" label="备注" />
      <el-table-column prop="receivingAddress" label="收货地址" />
      <el-table-column prop="reminderTime" label="提醒时长" />
      <el-table-column
        label="操作"
        align="center"
        width="200"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="customerPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  addCustomer,
  deleteCustomer,
  updateCustomer,
  selectCustomerInfo,
  selectCustomerList
} from '@/api/sale/customer'
import { selectUserList } from '@/api/system/user'

export default {
  data () {
    return {
      customerDialogVisible: false,
      customerFormTitle: '',
      customerForm: {
        id: '',
        customerName: '',
        area: '',
        postalCode: '',
        contactPerson: '',
        phone: '',
        contactAddress: '',
        businessOwnerId: '',
        businessOwner: '',
        remarks: '',
        receivingAddress: '',
        reminderTime: ''
      },
      customerFormRules: {
        customerName: [{ required: true, message: '客户名称不能为空', trigger: ['blur', 'change']}]
      },
      customerPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        customerName: ''
      },
      userList: []
    }
  },
  created () {
    selectCustomerList(this.searchForm).then(res => {
      this.customerPage = res
    })
    selectUserList({ deptId: window.g.SALE_DEPT_ID }).then(res => {
      this.userList = res.list
    })
  },
  methods: {
    customerDialogClose () {
      this.$refs.customerFormRef.resetFields()
    },
    customerFormSubmit () {
      if (this.customerFormTitle === '客户信息登记表详情') {
        this.customerDialogVisible = false
        return
      }
      this.$refs.customerFormRef.validate(async valid => {
        if (valid) {
          // let user = this.userList.find(item => item.id === this.customerForm.businessOwnerId)
          // this.customerForm.businessOwner = user.realName
          this.customerForm.receivingAddress = this.customerForm.receivingAddress.toString()
          if (this.customerFormTitle === '新增客户信息登记表') {
            this.customerForm.id = ''
            await addCustomer(this.customerForm)
          } else if (this.customerFormTitle === '修改客户信息登记表') {
            await updateCustomer(this.customerForm)
          }
          this.customerPage = await selectCustomerList(this.searchForm)
          this.customerDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.customerFormTitle = '新增客户信息登记表'
      this.customerDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteCustomer(row.id)
        if (this.customerPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.customerPage = await selectCustomerList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.customerFormTitle = '修改客户信息登记表'
      this.customerDialogVisible = true
      this.selectCustomerInfoById(row.id)
    },
    handleInfo (index, row) {
      this.customerFormTitle = '客户信息登记表详情'
      this.customerDialogVisible = true
      this.selectCustomerInfoById(row.id)
    },
    selectCustomerInfoById (id) {
      selectCustomerInfo(id).then(res => {
        this.customerForm.id = res.id
        this.customerForm.customerName = res.customerName
        this.customerForm.area = res.area
        this.customerForm.postalCode = res.postalCode
        this.customerForm.contactPerson = res.contactPerson
        this.customerForm.phone = res.phone
        this.customerForm.contactAddress = res.contactAddress
        this.customerForm.businessOwnerId = res.businessOwnerId
        this.customerForm.businessOwner = res.businessOwner
        this.customerForm.remarks = res.remarks
        this.customerForm.receivingAddress = res.receivingAddress.split(',')
        this.customerForm.reminderTime = res.reminderTime
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectCustomerList(this.searchForm).then(res => {
        this.customerPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectCustomerList(this.searchForm).then(res => {
        this.customerPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectCustomerList(this.searchForm).then(res => {
        this.customerPage = res
      })
    }
  }
}
</script>

<style>
</style>
